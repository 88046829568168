import { defineStore } from 'vue_features/shared/composables/store_helpers.js'
import { computed, reactive } from 'vue'

const uniqueBy = (list = [], uniquenessIdentifier = (a) => a) => {
  return Object.values(
    list.reduce((uniqueItems, item) => {
      uniqueItems[uniquenessIdentifier(item)] ||= item
      return uniqueItems
    }, {}),
  )
}

export const useLessonPlanStandardsStore = defineStore('lessonPlanStandards', () => {
  const state = reactive({
    standards: [],
    customerVisibleStandards: [],
  })

  const allStandards = computed(() => uniqueBy(state.standards, (standard) => standard.displayCode))

  const allCustomerVisibleStandards = computed(() =>
    uniqueBy(state.customerVisibleStandards, (standard) => standard.displayCode),
  )

  return {
    state,
    allStandards,
    allCustomerVisibleStandards,
  }
})
